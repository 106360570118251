import React, { useState } from 'react'

// Components
import Layout from '../layouts'
import Nav from '../components/Nav'
import Header from '../components/Header'
import HowItWorks from '../components/HowItWorks'
import Footer from '../components/Footer'
import Tracker from '../components/Tracker'
import CookieConsent from '../components/CookieConsent'
import Pricing from '../components/Pricing'
import PropTypes from 'prop-types'

function Main({activePane}) {
  const [activePanel, setActivePanel] = useState(activePane)
  return (
    <Layout pageName="home">
      <Nav/>
      <Header setActivePanel={setActivePanel} activePanel={activePanel}/>
      <CookieConsent/>
      <HowItWorks activePanel={activePanel}/>
      <Pricing/>
      <Footer activePanel={activePanel}/>
      <Tracker/>
    </Layout>
  )
}

Main.propTypes = {
  activePane: PropTypes.string
}

export default Main
