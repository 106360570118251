import React from 'react'
import PropTypes from 'prop-types'

function Row({
  item: {
    id,
    subtitle,
    title,
    paragraph
  }
}) {
  return (
    <div className="feature-row">
      <div>
        <img
          src={require(`../images/screens/${id}@2x.png`)}
          alt={id}
        />
      </div>
      <div className="feature-content">
        <div>
          <h5>{subtitle}</h5>
          <h3>{title}</h3>
          <p>
            {paragraph}
          </p>
        </div>
      </div>
    </div>
  )
}

Row.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    paragraph: PropTypes.string,
    options: PropTypes.array
  })
}

export default Row
