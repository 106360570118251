import logo from './gatsby.svg'
import desktop from './desktop@2x.png'
import mobile from './mobile@2x.png'
import shapes from './shapes.svg'
import chevron from './chevron.svg'

export default {
  shapes,
  logo,
  desktop,
  mobile,
  logoPlaceholder: 'https://via.placeholder.com/155x55/CCD1DB',
  chevron
}
