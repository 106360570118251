import React, { useState } from 'react'

export function Rocket({started, mouseOutAnimationEnabled}) {

  return (
    <svg class={started ? "rocket clicked" : mouseOutAnimationEnabled ? "rocket withMouseOutAnimation" : "rocket"} xmlns="http://www.w3.org/2000/svg" width="77" height="66" viewBox="0 0 77 66">
      <defs>
        <linearGradient id="rocket-a" x1="99.953%" x2="-.031%" y1="49.993%" y2="49.993%">
          <stop offset="0%" stopColor="#FF6D00"/>
          <stop offset="24.87%" stopColor="#FF9B00"/>
          <stop offset="51.35%" stopColor="#FFC600"/>
          <stop offset="72.1%" stopColor="#FFE000"/>
          <stop offset="84.39%" stopColor="#FFEA00"/>
        </linearGradient>
        <linearGradient id="rocket-b" x1="77.083%" x2="-5.129%" y1="22.093%" y2="106.826%">
          <stop offset=".445%" stopColor="#C62828"/>
          <stop offset="27.11%" stopColor="#D75327"/>
          <stop offset="59.83%" stopColor="#E98126"/>
          <stop offset="85.04%" stopColor="#F59D25"/>
          <stop offset="99.53%" stopColor="#F9A825"/>
        </linearGradient>
        <radialGradient id="rocket-c" cx="61.628%" cy="19.753%" r="51.435%" fx="61.628%" fy="19.753%"
                        gradientTransform="matrix(-1 0 0 -.99977 1.233 .395)">
          <stop offset="1.12%" stopColor="#F5F5F5"/>
          <stop offset="99.95%" stopColor="#BDBDBD"/>
        </radialGradient>
      </defs>
      <g id="rocket" opacity="0" fill="none" transform="rotate(45 14.25 34.403) translate(8,-8)">
        <path fill="url(#rocket-a)"
              d="M3.44791999,29.4821599 C0.372239999,32.5578399 0.206799999,38.1639999 0.206799999,38.1639999 C0.206799999,38.1639999 5.80919999,37.9985599 8.88863998,34.9228799 C11.96808,31.8471999 13.24272,28.1360799 11.73872,26.6358399 C10.23472,25.1318399 6.52359998,26.4064799 3.44791999,29.4821599 Z"/>
        <path fill="#424242"
              d="M9.98279997,0.812159998 C10.52048,0.812159998 10.93032,0.951279998 11.2048,1.222 C12.2388,2.25599999 11.32136,5.47831999 8.35471998,8.44871998 C6.21151998,10.59192 2.64327999,11.2236 1.02648,11.40408 C1.20696,9.78727998 1.83864,6.21903998 3.97807999,4.07583999 C5.95959998,2.09055999 8.31711998,0.812159998 9.98279997,0.812159998 M9.98279997,0.0601599998 C8.17423998,0.0601599998 5.64751999,1.33856 3.44791999,3.54191999 C0.372239999,6.61759998 0.206799999,12.22376 0.206799999,12.22376 C0.206799999,12.22376 5.80919999,12.05832 8.88863998,8.98263998 C11.96808,5.90695999 13.24272,2.19583999 11.73872,0.695599998 C11.31008,0.259439999 10.70472,0.0601599998 9.98279997,0.0601599998 Z"
              opacity=".2" transform="translate(0 25.944)"/>
        <path fill="url(#rocket-b)"
              d="M4.38039999,30.5650399 C2.44399999,32.5014399 2.33871999,36.0283199 2.33871999,36.0283199 C2.33871999,36.0283199 5.86559999,35.9230399 7.80199998,33.9866399 C9.73839998,32.0502399 10.54304,29.7115199 9.59551998,28.7677599 C8.64799998,27.8239999 6.32055998,28.6248799 4.38039999,30.5650399 L4.38039999,30.5650399 Z"/>
        <path fill="#546E7A"
              d="M8.35095998,30.5988799 C10.19336,32.5653599 12.61104,32.3585599 12.81032,32.0351999 L14.4948,29.2979199 C14.59632,29.1324799 14.50232,28.8692799 14.43088,28.8015999 L9.88503997,24.2557599 C9.81359998,24.1843199 9.56167998,24.1015999 9.39623998,24.2031199 L6.65895998,25.8875999 C6.33935998,26.0868799 5.97839998,28.0608799 8.35095998,30.5988799 L8.35095998,30.5988799 Z"/>
        <path fill="#0288D1"
              d="M20.7288799,35.5996799 C20.3528799,35.9042399 19.8490399,35.8365599 19.7851199,35.3853599 C19.6084,34.1370399 19.7249599,31.6291199 18.15328,30.0574399 C16.6192,28.5233599 14.12632,28.2977599 14.12632,28.2977599 L23.5112799,23.6729599 C23.5112799,23.6729599 25.2784799,26.3989599 24.3046399,30.2792799 C23.4924799,33.4865599 22.1050399,34.4754399 20.7288799,35.5996799 L20.7288799,35.5996799 Z"/>
        <path fill="#424242"
              d="M9.06911998,1.87624 C9.13679998,2.06047999 9.20823998,2.27103999 9.27215998,2.50415999 C9.52783998,3.43663999 9.74215998,4.92183999 9.29471998,6.68903998 C8.72695998,8.95255998 7.87719998,9.92263997 6.88079998,10.7724 C6.75295998,9.33607998 6.47471998,7.38087998 5.03463999,5.94455998 C4.50823999,5.41815999 3.89159999,5.01959999 3.28623999,4.72255999 L9.06911998,1.87624 M9.59551998,0.360959999 L0.210559999,4.98575999 C0.210559999,4.98575999 2.70719999,5.21511999 4.23751999,6.74543998 C5.80919999,8.31711998 5.69263999,10.82504 5.86935999,12.07336 C5.90695999,12.34032 6.10247998,12.47568 6.33183998,12.47568 C6.48975998,12.47568 6.66271998,12.41176 6.81311998,12.28768 C8.18927998,11.16344 9.57671998,10.17456 10.38512,6.96727998 C11.36648,3.08319999 9.59551998,0.360959999 9.59551998,0.360959999 L9.59551998,0.360959999 Z"
              opacity=".2" transform="translate(13.912 23.312)"/>
        <path fill="#0288D1"
              d="M2.88391999,18.2924 C2.51543999,18.60824 2.64703999,19.17224 3.09823999,19.23616 C4.34655999,19.41288 6.85447998,19.29632 8.42615998,20.8679999 C9.96023997,22.4020799 10.27984,24.5753599 10.27984,24.5753599 L14.8144,14.97608 C14.8144,14.97608 12.41552,13.85184 8.92623998,14.758 C6.51983998,15.38592 4.13223999,17.21704 2.88391999,18.2924 Z"/>
        <path fill="#424242"
              d="M9.00143998,1.24456 C9.62935998,1.24456 10.1708,1.31224 10.59944,1.39496 L7.77943998,7.37335998 C7.47863998,6.83567998 7.09135998,6.28295998 6.59127998,5.78663999 C5.25647999,4.45183999 3.48175999,4.11719999 2.08303999,3.97431999 C3.29751999,3.04935999 4.94815999,1.98903999 6.57247998,1.56416 C7.38463998,1.34984 8.20055998,1.24456 9.00143998,1.24456 M9.00143998,0.11656 C8.20807998,0.11656 7.29439998,0.210559999 6.29423998,0.469999999 C3.88783999,1.09792 1.504,2.92903999 0.255679999,4.00439999 C-0.1128,4.32023999 0.0188,4.88423999 0.469999999,4.94815999 C1.71832,5.12487999 4.22623999,5.00831999 5.79791999,6.57999998 C7.33199998,8.11407998 7.65159998,10.28736 7.65159998,10.28736 L12.1824,0.688079998 C12.1824,0.688079998 10.96416,0.11656 9.00143998,0.11656 L9.00143998,0.11656 Z"
              opacity=".2" transform="translate(2.632 14.288)"/>
        <path fill="#424242"
              d="M3.10951999,1.40248 L6.83943998,5.13239999 L5.66255999,7.04623998 C5.62495999,7.04999998 5.58359999,7.04999998 5.53847999,7.04999998 C5.17375999,7.04999998 3.90287999,6.95599998 2.78239999,5.76031999 C1.36488,4.24503999 1.14304,3.06063999 1.16184,2.59815999 L3.10951999,1.40248 M3.18847999,0.0902399998 C3.12455999,0.0902399998 3.06063999,0.10528 3.00423999,0.13536 L0.266959999,1.81984 C-0.0563999999,2.01911999 -0.417359999,3.99311999 1.9552,6.53111998 C3.16215999,7.82079998 4.61727999,8.17799998 5.53471999,8.17799998 C6.01599998,8.17799998 6.34687998,8.08023998 6.41455998,7.96743998 L8.09903998,5.23015999 C8.20055998,5.06471999 8.10655998,4.80151999 8.03511998,4.73383999 L3.49303999,0.19176 C3.44791999,0.14664 3.32007999,0.0902399998 3.18847999,0.0902399998 L3.18847999,0.0902399998 Z"
              opacity=".2" transform="translate(6.392 24.064)"/>
        <path fill="url(#rocket-c)"
              d="M26.7223199,2.44023999 C28.2451199,3.13959999 30.2980799,4.34279999 32.2570399,6.30175998 C32.9488799,6.99359998 33.5391999,7.70047998 34.0505599,8.39231998 C35.0394399,9.72711998 35.7162399,11.00176 36.1599199,11.99816 C35.1710399,14.59632 33.3060799,17.61936 31.4824799,19.44296 C24.0339199,26.8915199 15.80704,31.1440799 11.55072,28.1323199 C11.30256,27.9555999 11.05816,27.7939199 10.83632,27.5946399 C7.18535998,24.3121599 11.36648,14.91592 18.96168,7.32071998 C20.8679999,5.41439999 24.0451999,3.46671999 26.7223199,2.44023999 Z"/>
        <circle cx="24.741" cy="13.228" r="3.346" fill="#455A64"/>
        <path fill="#424242"
              d="M3.68479999,0.857279998 C4.37663999,0.857279998 5.03087999,1.128 5.51967999,1.6168 C6.00847998,2.10559999 6.27919998,2.75983999 6.27919998,3.45167999 C6.27919998,4.14351999 6.00847998,4.79775999 5.51967999,5.28655999 C5.03087999,5.77535999 4.37663999,6.04607998 3.68479999,6.04607998 C2.99295999,6.04607998 2.33871999,5.77535999 1.84992,5.28655999 C0.838479998,4.27511999 0.838479998,2.62823999 1.84992,1.6168 C2.33871999,1.128 2.98919999,0.857279998 3.68479999,0.857279998 M3.68479999,0.10528 C2.82751999,0.10528 1.97024,0.432399999 1.316,1.08664 C0.00751999998,2.39511999 0.00751999998,4.51199999 1.316,5.82047999 C1.97024,6.47471998 2.82751999,6.80183998 3.68479999,6.80183998 C4.54207999,6.80183998 5.39935999,6.47471998 6.05359998,5.82047999 C7.36207998,4.51199999 7.36207998,2.39511999 6.05359998,1.08664 C5.39935999,0.432399999 4.54207999,0.10528 3.68479999,0.10528 L3.68479999,0.10528 Z"
              opacity=".2" transform="translate(21.056 9.776)"/>
        <circle cx="17.837" cy="20.556" r="2.421" fill="#455A64"/>
        <path fill="#E53935"
              d="M26.7223199,2.44023999 C26.0267199,5.87311999 32.0878399,12.596 36.1599199,11.99816 C37.4270399,8.72319998 37.9459199,6.50103998 38.2918399,2.67711999 C38.4685599,0.740719998 37.9007999,0.188 36.1072799,0.398559999 C31.3508799,0.955039998 29.4708799,1.316 26.7223199,2.44023999 Z"/>
        <path fill="#424242"
              d="M27.3502399,1.4852 C27.5758399,1.4852 27.6886399,1.51152 27.7299999,1.52656 C27.7600799,1.59424 27.8352799,1.85744 27.7713599,2.57559999 C27.4367199,6.27919998 26.9554399,8.38479998 25.7108799,11.59584 C24.7558399,14.09624 22.9359999,16.9952 21.2853599,18.64584 C15.5288,24.4023999 9.48271998,27.9819199 5.50463999,27.9819199 C4.43303999,27.9819199 3.52311999,27.7224799 2.80119999,27.2111199 L2.67711999,27.1246399 C2.48535999,26.9930399 2.32367999,26.8764799 2.18831999,26.7561599 C1.5792,26.2071999 1.25584,25.3687199 1.22576,24.2632799 C1.12048,20.2400799 4.87295999,13.59992 10.35504,8.11783998 C12.07336,6.39951998 15.10392,4.49695999 17.72088,3.49679999 L17.74344,3.48551999 C20.4017599,2.39887999 22.2516799,2.05671999 26.8313599,1.51904 C27.0306399,1.49648 27.2035999,1.4852 27.3502399,1.4852 M27.3502399,0.357199999 C27.1547199,0.357199999 26.9441599,0.372239999 26.7072799,0.398559999 C21.9508799,0.955039998 20.0708799,1.316 17.32232,2.44023999 C14.6452,3.46295999 11.468,5.41063999 9.56167998,7.31695998 C1.96648,14.91216 -2.21463999,24.3083999 1.43632,27.5908799 C1.6544,27.7863999 1.8988,27.9518399 2.15071999,28.1285599 C3.09071999,28.7940799 4.22623999,29.1061599 5.50463999,29.1061599 C10.00912,29.1061599 16.2808,25.2446399 22.0862399,19.44296 C23.9098399,17.61936 25.7747999,14.59632 26.7636799,11.99816 C28.0307999,8.72319998 28.5496799,6.50103998 28.8955999,2.67711999 C29.0422399,0.996399997 28.6361599,0.357199999 27.3502399,0.357199999 L27.3502399,0.357199999 Z"
              opacity=".2" transform="translate(9.4)"/>
        <path fill="#424242"
              d="M2.42143999,0.838479998 C2.86887999,0.838479998 3.28623999,1.01144 3.60207999,1.32728 C3.91791999,1.64312 4.09087999,2.06047999 4.09087999,2.50791999 C4.09087999,2.95535999 3.91791999,3.37271999 3.60207999,3.68855999 C3.28623999,4.00439999 2.86887999,4.17735999 2.42143999,4.17735999 C1.97399999,4.17735999 1.55664,4.00439999 1.2408,3.68855999 C0.924959998,3.37271999 0.751999998,2.95535999 0.751999998,2.50791999 C0.751999998,2.06047999 0.924959998,1.64312 1.2408,1.32728 C1.5604,1.01144 1.97775999,0.838479998 2.42143999,0.838479998 M2.42143999,0.0864799998 C1.80104,0.0864799998 1.1844,0.323359999 0.710639998,0.797119998 C-0.233119999,1.74088 -0.233119999,3.27495999 0.710639998,4.21871999 C1.1844,4.69247999 1.80104,4.92935999 2.42143999,4.92935999 C3.04183999,4.92935999 3.65847999,4.69247999 4.13223999,4.21871999 C5.07599999,3.27495999 5.07599999,1.74088 4.13223999,0.797119998 C3.66223999,0.323359999 3.04183999,0.0864799998 2.42143999,0.0864799998 L2.42143999,0.0864799998 Z"
              opacity=".2" transform="translate(15.416 18.048)"/>
      </g>
      <g id="spark-top" width="7" opacity="0">
        <rect width="10" height="2" x="77" y="374" fill="#FFDB4D" fillRule="evenodd"
              transform="translate(-69 -351)" />
      </g>
      <g id="spark-mid" width="7" opacity="0">
        <rect width="10" height="2" x="77" y="377.5" fill="#FFDB4D" fillRule="evenodd"
              transform="translate(-69 -351)" />
      </g>
      <g id="spark-bot" width="7" opacity="0" >
        <rect width="10" height="2" x="77" y="381" fill="#FFDB4D" fillRule="evenodd"
              transform="translate(-69 -351)" />
      </g>
    </svg>
  )
}
