import React, { PureComponent } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Link } from 'gatsby'

/* The settings for the css transition. */
const transitionOptions = {
  classNames: 'cookie-notification__animation',
  timeout: {
    enter: 500,
    exit: 500
  },
  unmountOnExit: true
}

export default class CookieNotification extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      acceptedCookies: true
    }
  }

  componentDidMount() {
    const acceptedCookies = localStorage.getItem('accepted-cookies') || false

    this.setState({
      acceptedCookies
    })
  }

  handleAcceptCookies = () => {
    localStorage.setItem('accepted-cookies', 'true')

    this.setState({
      acceptedCookies: true
    })
  }

  render() {
    const { acceptedCookies } = this.state
    return (
      <CSSTransition {...transitionOptions} in={!acceptedCookies}>
        <section className={`cookie-notification cookie-notification__${acceptedCookies}`}>
          <svg onClick={this.handleAcceptCookies} className="close" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path
              fill="#9AACC3"
              d="M12,13.4142136 L4.70710678,20.7071068 C4.31658249,21.0976311 3.68341751,21.0976311 3.29289322,20.7071068 C2.90236893,20.3165825 2.90236893,19.6834175 3.29289322,19.2928932 L10.5857864,12 L3.29289322,4.70710678 C2.90236893,4.31658249 2.90236893,3.68341751 3.29289322,3.29289322 C3.68341751,2.90236893 4.31658249,2.90236893 4.70710678,3.29289322 L12,10.5857864 L19.2928932,3.29289322 C19.6834175,2.90236893 20.3165825,2.90236893 20.7071068,3.29289322 C21.0976311,3.68341751 21.0976311,4.31658249 20.7071068,4.70710678 L13.4142136,12 L20.7071068,19.2928932 C21.0976311,19.6834175 21.0976311,20.3165825 20.7071068,20.7071068 C20.3165825,21.0976311 19.6834175,21.0976311 19.2928932,20.7071068 L12,13.4142136 Z"
              transform="translate(-3 -3)"
            />
          </svg>
          <h4>
            Cookie notice
          </h4>
          <p>
          We use cookies to help us offer you the best online experience. By continuing to use our website, you're agreeing to our use of cookies in accordance with our cookies policy.
            <Link to="/cookies" className="text-link"> Learn more</Link>
          </p>
        </section>
      </CSSTransition>
    )
  }
}
