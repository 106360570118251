import React from 'react'

// Config
import { config } from '../config'

const pricings = [
  {
    title: 'Personal',
    emoji: 'personal',
    tag: 'Free',
    description: 'Get input from everyone and sift through things to do whatever.',
    link: `${config.webAppUrl}/templates`
  },
  {
    title: 'Academic',
    emoji: 'academic',
    tag: 'Free',
    description: 'Get student input and explore perspectives to evolve understanding.',
    link: `${config.webAppUrl}/templates#workspace-academic`
  },
  {
    title: 'Enterprise',
    emoji: 'enterprise',
    tag: 'Free+',
    description: 'Get input and sift through options to move forward together.',
    sub: '$9/user per month for Enterprise User Management',
    link: `${config.webAppUrl}/templates`
  }
]

function Pricing() {
  return (
    <section className="pricing">
      <div className="container-md">
        <div className="items">
          {
            pricings.map(({
              title, tag, description, emoji, sub, link
            }, idx) => (
              <div className="item" key={idx}>
                <div>
                  <div className="title">
                    <div>
                      <img
                        src={require(`../images/emojis/${emoji}.png`)}
                        alt={emoji}
                      />
                      <h2>
                        {title}
                      </h2>
                    </div>

                    <span className="tag">{tag}</span>
                  </div>
                  <p>{description}</p>

                  {
                    sub && (
                      <p className="small">{sub}</p>
                    )
                  }
                </div>

                <a data-tracker={title + " - Get Started"} className="btn secondary small" href={link}>
                  Get started
                </a>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default Pricing
